import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import * as React from "react";

if (window.config.GoogleAnalyticsTrackingID != null && window.config.GoogleAnalyticsTrackingID.length > 0) {
    ReactGA.initialize(window.config.GoogleAnalyticsTrackingID);
}
function GoogleAnalytics() {

    const location = useLocation();
    React.useEffect(() => {
        if (window.config.GoogleAnalyticsTrackingID != null && window.config.GoogleAnalyticsTrackingID.length > 0) {
            ReactGA.send({
                hitType: "pageview",
                page: window.location.pathname + window.location.search,
                //title: "Custom Title"
            });
        }
        console.log("useEffect: " + window.location.pathname + window.location.search)
    }, [location]);

    return (
        <></>
    )
}
export default GoogleAnalytics;