import * as React from 'react';

export function useTitle(...title) {

    let localTitle = "Utils-Zone";
    if (title != null) {
        for (let i in title) {
            localTitle += " :: " + title[i];
        }
    }

    React.useEffect(() => {
        const prevTitle = document.title;
        document.title = localTitle;
        return () => {
            document.title = prevTitle;
        }
    }, []);
}