import {useTitle} from "../utils";

function Home() {
    useTitle();

    return (
        <div>
            Welcome to Utils-Zone.
        </div>
    );
}

export default Home;