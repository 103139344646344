import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import * as React from 'react';
import Header from "./Header";
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import Home from "./app/Home";
import Container from "react-bootstrap/Container";
import CookieConsent from "react-cookie-consent";
import GoogleAnalytics from "./GoogleAnalytics";

const EncodingBase64 = React.lazy(() => import('./app/encoding/Base64'));
const EncodingTimestamp = React.lazy(() => import('./app/encoding/Timestamp'));
const NetworkMyIP = React.lazy(() => import('./app/network/MyIP'));

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Header/>
                <main>
                    <Container className="pt-4">
                        <React.Suspense fallback={<span>Loading ...</span>}>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/encoding/base64" element={<EncodingBase64/>}/>
                                <Route path="/encoding/timestamp" element={<EncodingTimestamp/>}/>
                                <Route path="/network/my-ip" element={<NetworkMyIP/>}/>
                            </Routes>
                        </React.Suspense>
                    </Container>
                </main>
                <GoogleAnalytics />
            </BrowserRouter>
            <CookieConsent
                cookieName="UtilsZoneCookiesConsent"
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </div>
    );
}

export default App;
