import React from "react";
import { LinkContainer } from 'react-router-bootstrap'
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function Header() {
    return (
        <Navbar expand="lg" bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="/" style={{color: "#4CAF50"}}>Utils-Zone</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to="/">
                            <Nav.Link >Home</Nav.Link>
                        </LinkContainer>
                        <NavDropdown title="Encoding" id="encoding-nav-dropdown">
                            <LinkContainer to="/encoding/base64">
                                <NavDropdown.Item>Base 64</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/encoding/timestamp">
                                <NavDropdown.Item>Unix Timestamp</NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                        <NavDropdown title="Network" id="network-nav-dropdown">
                            <LinkContainer to="/network/my-ip">
                                <NavDropdown.Item>My IP</NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}